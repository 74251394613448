import PropTypes from 'prop-types'
import React from 'react'
import Video from './video.js'
import about from '../images/about.png'
import programFlyer from '../images/Program_Flyer.jpeg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >

        <article
          id="programs"
          className={`${this.props.article === 'programs' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h1 className="major">Programs</h1>
          <div
            style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
		{/*
                window.location.href="https://flote.app/user/mehfilealasr/live"
                */}
                window.location.href="https://www.youtube.com/channel/UCZIOOL3LwuipVTqbatjDJaQ/live"
              }}
              style={{ alignItems: 'center' }}
            >
              Watch Live
            </button>
          <p></p>
          </div>
          <span className="image main">
            <img src={programFlyer} alt="" />
          </span>
          {/*
          <p><em>Note: For mobile and tablet users, we recommend first downloading the Jitsi Meet app from the app store and then joining the program for a smoother experience.</em></p>
          */}
          {close}
        </article>

        <article
          id="downloads"
          className={`${this.props.article === 'downloads' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h1 className="major">Downloads</h1>
          <h2>Shaban 15</h2>
          <a href="/static/Ariza.pdf">Ariza.pdf</a>
          <br />
          <a href="/static/Kids Ariza.pdf">Kids Ariza.pdf</a>
          <p></p>
          {close}
	</article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h1 className="major">About</h1>
          <p>
            Mehfile al-Asr, aka Al-Asr Jamaat, was founded by Marhoom Taher Mooman in 1994. Since that time it has served as a beacon of knowledge and worship for those who love Prophet Muhammad S.A.W. and his Ahlul Bayt A.S. It has blossomed into a community which fosters Azadaari and improves our readiness for the return of our living Imam Mehdi A.S.
          </p>
          <p>
            Our goal has been and continues to be to create an environment that foresters the development of our youth's nearness to Allah and love for his beloved. We encourage our youth to participate in our programs and help shape the future leaders of our faith.
          </p>
          <p><em>Please recite a Surah Fatiha and 3 times Surah Ikhlas for the late Taher Mooman, may his soul rest in peace alongside Prophet Muhammad S.A.W. and his Ahlul Bayt A.S., as well as for the marhoomeen of the entire Mooman family, your family, and all momineen and mominaat.</em></p>
          <span className="image main">
            <img src={about} alt="" />
          </span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h1 className="major">Contact</h1>
          <form method="post" action="mailto:info@mehfilealasr.org" method="post" enctype="text/plain">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
