import React from "react"

import '../assets/css/video.css'

const Video = ({ src, ...props }) => (
  <div className="video">
    <iframe
      src={src}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitAllowFullScreen="true"
      mozAllowFullScreen="true"
      allowFullScreen="true"
    />
  </div>
)

export default Video
